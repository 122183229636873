<template>
  <div>
    <div
      @click="openModalToClaim"
      class="holder"
      :class="[
        promoObject.token != null ? 'flex-row-token' : 'flex-row-notoken'
      ]"
      v-if="showMessage"
    >
      <!-- v-if="promoObject != null && promoObject.token == null" -->
      <h6 class="blink" v-if="promoObject != null && promoObject.token == null && !alreadyClaimed">
        🎉 Congratulations you’ve won ₦10,000 worth of electricity token.
        <span>Click here</span>
        to claim your gift.
      </h6>
      <h6 class="blink" v-if="alreadyClaimed && promoObject.token == null">
        🎉 Congratulations your token will be displayed here shortly.
      </h6>
      <h4
        class=""
        v-if="promoObject.closed == 'NO' && promoObject.token != null && alreadyClaimed"
      >
        🎉 Congratulations your gift is ready, “{{ promoObject.token }}”
      </h4>
      <small
        v-if="promoObject.closed == 'NO' && promoObject.token != null"
        @click="closeModal($event)"
        >X</small
      >
    </div>

    <!-- Reward claim modal -->
    <div
      class="modal fade"
      id="rewarddetails"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content claim-content">
          <div class="modal-header claim-header">
            <div class="top-text">
              <h5 class="modal-title" id="exampleModalLabel">
                Redeem Electricity Token
              </h5>
              <p>Please provide the following details to redeem your prize:</p>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style="text-decoration: none;"
                >&times;</span
              >
            </button>
          </div>
          <div class="modal-body">
            <form class="reward-body-claim">
              <div class="form-group invite_holder">
                <label for="meternumber">Meter Number</label>
                <input
                  type="number"
                  class="form-control"
                  id="meternumber"
                  v-model="meternumber"
                  placeholder="Enter meter number"
                  :class="{ errorData: info && location === '' }"
                />
              </div>
              <div class="form-group invite_holder">
                <label for="metername">Name On Meter</label>
                <input
                  type="text"
                  class="form-control"
                  id="metername"
                  v-model="metername"
                  placeholder="Enter meter name"
                  :class="{ errorData: info && location === '' }"
                />
              </div>
              <div class="form-group">
                <label for="location">Location</label>
                <select
                  class="form-control"
                  id="location"
                  v-model="statename"
                  :class="{ errorData: info && location === '' }"
                >
                  <option selected disabled value="">--Select--</option>
                  <option
                    v-for="(item, index) in states"
                    :key="index"
                    :value="item.name"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
            </form>
          </div>

          <div class="form-group">
            <div class="claim-button">
              <button
                @click="claimReward"
                class="btn btn-block"
                :disabled="claimLoader"
              >
                SUBMIT
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="claimLoader"
                ></i>
              </button>
            </div>
          </div>
          <!-- <div class="modal-footer invite_footer">
            <div class="footer_btn_holder">
              <button type="button" class="btn closeT" data-dismiss="modal">
                Close
              </button>
            </div>
            <div class="base">
              <p>
                <span>Note: </span> Shelta credits you with ₦250 and credits
                your friend with ₦1,000 when they activate their SAFE.
                <span class="d-none">T&C Applies</span>
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Reward claim modal ends -->

    <!-- Close reward modal -->
    <div
      class="modal fade"
      id="closereward"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content claim-content">
          <h4>Closing this means you wont see this token here again?</h4>

          <div class="form-group">
            <div class="claim-button-close">
              <button class="btn" data-dismiss="modal" aria-label="Close">
                Cancel
              </button>
              <button
                @click="closeModalToClaim"
                class="btn"
                :disabled="closeLoader"
              >
                Continue
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="closeLoader"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Close Reward -->
  </div>
</template>

<script>
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
export default {
  name: "RewardMessage",
  components: {},
  data() {
    return {
      info: false,
      metername: "",
      meternumber: "",
      location: "",
      claimLoader: false,
      states: "",
      statename: "",
      user: "",
      promoObject: "",
      showMessage: false,
      closeLoader: false,
      alreadyClaimed: false
    };
  },

  mounted() {
    this.getStates();
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.getUserDetails();
  },
  methods: {
    openModalToClaim() {
      if (this.alreadyClaimed == false) {
        $("#rewarddetails")
          .addClass("fade")
          .modal("show");
      }
    },
    closeModalToClaim() {
      this.closeLoader = true;
      Api.postRequest(`claimrewardforpromo`, {
        promoid: this.promoObject.id
      })
        .then(res => {
          this.closeLoader = false;
          if (res.data.success) {
            this.getUserDetails();
            $("#closereward")
              .removeClass("fade")
              .modal("hide");
            return this.$store.commit(
              "setApiSuccess",
              `You have successfully claimed your token reward.`
            );
          }
          if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.closeLoader = false;
          console.log(err);
        });
    },
    closeModal(event) {
      event.stopPropagation();
      $("#closereward")
        .addClass("fade")
        .modal("show");
    },
    getUserDetails() {
      Api.getRequest(`getuserbyid/${this.user.id}`)
        .then(res => {
          // console.log("user data", res);
          if (res.data.success) {
            this.promoObject = res.data.promoObject;
            if (this.promoObject != null) {
              this.showMessage = true;
              if (
                this.promoObject.meterno != null ||
                this.promoObject.name_on_meter != null
              ) {
                this.alreadyClaimed = true;
                // console.log("claimed already", this.alreadyClaimed);
              }
            }

            // console.log("Promp id", this.promoObject.id);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async claimReward() {
      this.claimLoader = true;
      // this.info = true;
      // if (this.promoid === "") {
      //   this.info = true;
      //   this.claimLoader = false;
      //   return this.$store.commit("setApiFailed", "Promo id is required");
      // }
      if (this.metername === "") {
        this.info = true;
        this.claimLoader = false;
        return this.$store.commit("setApiFailed", "Meter name is required");
      }
      if (this.meternumber === "") {
        this.info = true;
        this.claimLoader = false;
        return this.$store.commit("setApiFailed", "Meter number is required");
      }
      if (this.statename === "") {
        this.info = true;
        this.claimLoader = false;
        return this.$store.commit("setApiFailed", "Location is required");
      }
      try {
        await Api.postRequest(`updatemeternoinpromo`, {
          promoid: this.promoObject.id,
          state: this.statename,
          meterno: this.meternumber,
          name_on_meter: this.metername
        })
          .then(res => {
            this.claimLoader = false;
            // console.log("claim reward", res);
            if (res.data.success) {
              this.getUserDetails();
              $("#rewarddetails")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit(
                "setApiSuccess",
                `Your token is currently been processed.
              Please check back shortly`
              );
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
            this.claimLoader = false;
          });
      } finally {
        // console.log("Closed");
      }
    },
    async getStates() {
      // const id = this.countryId;
      try {
        await Api.getRequest(`fetchstatesbycountryid/${161}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.states = res.data.states;
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$error: #dc3545;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

.errorData {
  border: 0.5px solid $error !important;
}
.holder {
  justify-content: center;
  height: 60px;
  background: #3ec250;
  margin-left: 5px;
  margin-right: 5px;
  width: inherit;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;

  h6,
  h4 {
    color: $secondary;
    // text-align: left;
    font-family: Lato;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    // line-height: 150%;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    // small {
    //   color: #3ec250;
    //   position: absolute;
    //   width: 30px;
    //   height: 30px;
    //   font-size: 22px;
    //   right: 2%;
    //   top: 15px;
    //   background: #ffffff;
    //   padding: 2px;
    //   cursor: pointer;
    //   z-index: 10;
    // }
  }

  span {
    text-decoration: underline;
    // cursor: pointer;
    padding: 0 5px;
  }
}

.flex-row-notoken {
  justify-content: center;
}
.flex-row-token {
  display: flex;
  align-items: center;
  justify-content: space-between;
  small {
    color: #3ec250;
    // position: absolute;
    // width: 30px;
    // height: 30px;
    font-size: 20px;
    // right: 2%;
    // top: 15px;
    background: #ffffff;
    padding: 3px 10px;
    margin-left: 10px;
    cursor: pointer;
    z-index: 10;
    // margin-right: 10px;
  }
}

.blink {
  animation: blinker 3s linear infinite;
}

.holder {
  animation: horizontal-shaking 0.6s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

.top-text {
  h5 {
    color: #000a2f;
    text-align: left;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    margin-bottom: 8px;
  }

  p {
    color: #555c74;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
}

input,
select {
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  // background: #F8FAFC;
  height: 48px;
  outline: 0 !important;
  box-shadow: none !important;
  color: #000a2f;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaadba;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aaadba;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aaadba;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.claim-content {
  padding: 24px 25px 40px;
  .modal-header::after {
    border-bottom: 1px solid black !important;
  }
  h4 {
    color: #000a2f;
    padding-top: 45px;
  }
}

.claim-header {
  border-bottom: none;
}
.claim-header::after {
  border-bottom: 1px solid black !important;
}

.reward-body-claim {
  label {
    display: flex;
    color: #000a2f;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 4px;
  }
}

.reward-body-claim::before {
  border-top: 1px solid !important;
}

.claim-button-close {
  padding: 0 15px;
  margin-top: 40px;

  button:first-child {
    background: #ffffff;
    color: $error;
    border: 1px solid $error;
    height: 48px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
    margin-right: 15px;
  }
  button:nth-child(2) {
    background: $primary;
    color: $secondary;
    height: 48px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
  }
}
.claim-button {
  padding: 0 15px;
  margin-top: 8px;

  button {
    background: $primary;
    color: $secondary;
    height: 48px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
  }
}

@media only screen and (max-width: 599px) {
  .holder {
    height: fit-content;

    h6 {
      font-size: 16px;
    }
    h4 {
      small {
        top: 47%;
      }
    }
  }
}
</style>
