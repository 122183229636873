<template>
  <div>
    <div class="small-top-nav">
     
      <div class="top-nav">
        <img
          id="display"
          v-if="switchPropDisplay"
          src="../assets/images/switch-blue.svg"
          alt="switch"
        />
        <p v-else>{{ currentPath }}</p>
        <div class="invite_link">
          <p
            v-if="firstname != null && lastname != null"
            class="d-none d-lg-block"
          >
            Hello, {{ firstname }}
          </p>

          <button
            data-toggle="modal"
            data-target="#invite_friend"
            class="btn"
            v-show="
              currentPath == 'Safe' ||
                currentPath == 'SAFE' ||
                currentPath == 'Activate Safe' ||
                currentPath === ''
            "
          >
            Invite a friend
          </button>
        </div>
      </div>
      <RewardMessage></RewardMessage>
    </div>
    <!-- Modal to invite friend for safe begins -->
    <div
      class="modal fade"
      id="invite_friend"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header invite_header">
            <h5 class="modal-title" id="exampleModalLabel">
              Invite a friend
            </h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group invite_holder">
                <label for="fname">What's your friend's first name?</label>
                <input
                  type="text"
                  class="form-control"
                  id="fname"
                  v-model="fname"
                  placeholder="Friend's first name"
                />
              </div>
              <div class="form-group invite_holder">
                <label for="lname">What's your friend's last name?</label>
                <input
                  type="text"
                  class="form-control"
                  id="lname"
                  v-model="lname"
                  placeholder="Friend's last name"
                />
              </div>
              <div class="form-group invite_holder">
                <label for="email">What's your friend's email?</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                  placeholder="email@friend.com"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group invite_holder">
                <label for="phone">What's your friend's phone?</label>
                <input
                  type="number"
                  class="form-control"
                  id="phone"
                  v-model="phone"
                  placeholder="Enter friend's phone number"
                />
              </div>
            </form>
          </div>
          <p class="errorMessage">{{ errorMsg }}</p>
          <div class="modal-footer invite_footer">
            <div class="footer_btn_holder">
              <!-- @click="resendInvite = false" -->
              <button type="button" class="btn closeT" data-dismiss="modal">
                Close
              </button>
              <button
                v-if="!resendInvite"
                type="button"
                class="btn invite"
                @click="sendInviteLink"
              >
                <span>Invite</span>
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="inviteLoader"
                ></i>
              </button>
              <button
                v-if="resendInvite"
                type="button"
                class="btn invite2"
                @click="reSendInviteLink"
              >
                <span>Resend Invite</span>
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="inviteLoader"
                ></i>
              </button>
            </div>
            <div class="base">
              <p>
                <span>Note: </span> Shelta credits you with ₦250 and credits
                your friend with ₦1,000 when they activate their SAFE.
                <span class="d-none">T&C Applies</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to invite friend for safe ends -->
  </div>
</template>

<script>
import { Service } from "../store/service";
import RewardMessage from "./RewardMessage.vue";
const Api = new Service();
import $ from "jquery";
export default {
  name: "SmallNav",

  components: {
    RewardMessage
  },
  data() {
    return {
      inviteLoader: false,
      resendInvite: false,
      firstname: "",
      lastname: "",
      currentPath: "Overview",
      switchPropDisplay: false,
      fname: "",
      lname: "",
      email: "",
      phone: "",
      errorMsg: ""
    };
  },
  // computed: {
  //   currentRouteName() {
  //     let str = this.$route.name;
  //     str = str.substring(0, str.length - 1);
  //     return console.log("route name", str);
  //     // return this.$route.name;
  //   }
  // },
  mounted() {
    // let str = this.$route.name;
    // str = str.substring(0, str.length - 1);
    // console.log("ROUTE NAME", this.$route.name);
    this.firstname = JSON.parse(localStorage.getItem("shelta-user")).firstname;
    this.lastname = JSON.parse(localStorage.getItem("shelta-user")).lastname;
    this.currentPath = this.$route.path;
    this.propPin = this.$route.params.id;

    // let pat = this.$route.path;
    // // var s = '/Controller/Action';
    // let n = pat.splice('/');

    // console.log("sssssssssssssssss", n);
    // document.write(s);
    // if (this.currentPath == "/dashboard/safeprojectdetails/1") {

    // }
    if (
      this.currentPath === "/dashboard/landlord" ||
      this.currentPath === "/dashboard/tenant"
    ) {
      this.currentPath = "Overview";
    } else if (this.currentPath === "/dashboard/properties") {
      this.currentPath = "Properties";
    } else if (this.currentPath === `/dashboard/switch/${this.propPin}`) {
      this.switchPropDisplay = true;
    } else if (this.currentPath == "/dashboard/subscription") {
      this.currentPath = "Service Subscription";
    } else if (
      this.currentPath == `/dashboard/switchagreement/${this.propPin}`
    ) {
      this.currentPath = "Switch";
    } else if (this.currentPath == "/dashboard/e-wallet") {
      this.currentPath = "e-Wallet";
    } else if (this.currentPath == "/dashboard/rented") {
      this.currentPath = "Rented Properties";
    } else if (this.currentPath == "/dashboard/addlandlord") {
      this.currentPath = "Add Landlord/PM";
    } else if (this.currentPath == "/dashboard/paymentschedule") {
      this.currentPath = "My Property";
    } else if (this.currentPath == "/dashboard/addpm") {
      this.currentPath = "Property Manager";
    } else if (this.currentPath == "/dashboard/addtenant") {
      this.currentPath = "Add Tenant";
    } else if (this.currentPath == "/dashboard/facilityissues") {
      this.currentPath = "Facilities Issues";
    } else if (this.currentPath == "/dashboard/facilitycheck") {
      this.currentPath = "Facilities Report";
    } else if (this.currentPath == "/dashboard/rentfinancingagreement") {
      this.currentPath = "Agreement";
    } else if (this.currentPath == "/dashboard/safe") {
      this.currentPath = "SAFE";
    } else if (this.currentPath == "/dashboard/safeActivation") {
      this.currentPath = "Activate SAFE";
    } else if (this.currentPath == "/dashboard/safeschedule") {
      this.currentPath = "SAFE Schedule";
    } else if (this.currentPath == "/dashboard/newproject") {
      this.currentPath = "New Project";
    } else if (this.currentPath == "/dashboard/safeprojects") {
      this.currentPath = "My Projects";
    } else if (this.$route.name == "SafeProjectDetails") {
      this.currentPath = "Project Details";
    } else if (this.currentPath == "/dashboard/autodebithistory") {
      this.currentPath = "Auto Debit History";
    } else if (this.currentPath == "/dashboard/autodebit") {
      this.currentPath = "Auto Debit";
    } else if (this.currentPath == "/dashboard/safetransaction") {
      this.currentPath = "SAFE Transactions";
    } else if (this.currentPath == "/dashboard/editproject") {
      this.currentPath = "Edit Project";
    } else if (this.currentPath == "/dashboard/paymentbreakdown") {
      this.currentPath = "Payment breakdown";
    } else {
      this.currentPath = "Rent Now";
    }
    // this.currentPath = router.getMatchedComponents().pop().name
  },
  methods: {
    // This function call method from safe component
    callInviteLink: function() {
      this.$root.$refs.A.getInviteList();
    },
    sendInviteLink() {
      this.inviteLoader = true;
      const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!validRegex.test(this.email)) {
        this.errorMsg = "Invalid Email address!";
      }
      if (
        this.fname === "" ||
        this.lname === "" ||
        this.email === "" ||
        this.phone === ""
      ) {
        this.errorMsg = "All fields are required";
        this.inviteLoader = false;
      } else {
        Api.postRequest(`invitetosafe`, {
          firstname: this.fname,
          lastname: this.lname,
          email: this.email,
          phone: this.phone
        })
          .then(res => {
            console.log("Invite sent", res);
            this.inviteLoader = false;
            if (res.data.success) {
              this.$store.commit(
                "setApiSuccess",
                "Invite sent to friend's email"
              );
              this.resendInvite = true;
              this.callInviteLink();
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
              this.resendInvite = false;
            }
          })
          .catch(err => {
            console.log("err", err);
            this.inviteLoader = false;
            this.resendInvite = false;
          });
      }
    },
    reSendInviteLink() {
      this.inviteLoader = true;
      if (
        // this.fname === "" ||
        // this.lname === "" ||
        this.email === ""
        // this.phone === ""
      ) {
        this.errorMsg = "Fill the email field.";
        this.inviteLoader = false;
      } else {
        Api.getRequest(`resendinvite/${this.email}`)
          .then(res => {
            console.log("Invite sent", res);
            this.inviteLoader = false;
            if (res.data.success) {
              this.$store.commit(
                "setApiSuccess",
                "Invite resent to friend's email"
              );
              this.resendInvite = true;
              this.callInviteLink();
              $("#safe_terms")
                .removeClass("fade")
                .modal("hide");
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
              this.resendInvite = false;
            }
          })
          .catch(err => {
            console.log("err", err);
            this.inviteLoader = false;
            this.resendInvite = false;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$black: #000000;
$yellow: #ffb100;
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.small-top-nav {
  background: $secondary;
}
.top-nav {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  height: 3.5rem;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    align-items: center;
    color: $black;
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

.invite_link {
  display: flex;
  align-items: center;
  button {
    margin-left: 10px;
    border: 1px solid $primary;
    background: $secondary;
    color: $primary;
    width: 134px;
    // padding-bottom: 20px;
    // height: 35px;
    // line-height: 30px;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    outline: 0;
    box-shadow: none;
  }
}

.invite_header {
  padding-top: 5px;
  padding-bottom: 0;
  background: #e5e5e5;
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    color: $primary;
    margin: auto;
  }
}
.invite_holder {
  margin-top: 15px;
  padding: 0 30px;
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    color: $black;
    display: flex;
  }
  input {
    background: $secondary;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 45.57px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    box-shadow: none;
    outline: 0;
  }
}
.invite_footer {
  border-top: none;
  button {
    margin: 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    outline: 0;
  }
  .closeT {
    border: 1px solid #c4c4c4;
    border-radius: 50px;
    color: $black;
    min-width: 125.79px;
    width: 100%;
  }
  .invite {
    background: $primary;
    color: $secondary;
    border-radius: 50px;
    min-width: 125.79px;
    width: 100%;
  }
  .invite2 {
    background: $yellow;
    color: $black;
    border-radius: 50px;
    min-width: 140.79px !important;
    width: 100%;
  }
}
.footer_btn_holder {
  margin: auto;
  display: flex;
}

.errorMessage {
  font-family: Gotham;
  font-style: normal;
  color: #dc3545;
  font-size: 13px;
}

.base {
  background: #e5e5e5;
  border-radius: 0px 0px 5px 5px;
  margin-left: -12px;
  margin-right: -12px;
  padding: 0 10px;
  span:first-child {
    font-weight: bold;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    text-align: left;
    padding-top: 20px !important;
  }
  span:last-child {
    color: $primary;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 599px) {
  .top-nav {
    height: fit-content;
    img {
      width: 32%;
    }
    p {
      margin-bottom: 10px;
    }
  }
  .invite_link {
    display: block;
  }
  .invite_holder {
    padding: 0;
  }
}
</style>
